import React from "react"
import {
  Accordion,
  Row,
  Col,
  Container,
  Card,
  useAccordionToggle,
} from "react-bootstrap"
import ImageModal from "../../containers/ImageModal"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"

const StyledButton = styled.button`
  height: 100%;
  width: 100%;
  padding: 1.5rem;
  color: rgb(27, 27, 58);
  text-align: left;
  font-weight: bold;
  letter-spacing: 1px;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  outline: none !important;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`

const StyledCardBody = styled(Card.Body)`
  font-size: 17px;
  color: rgb(122, 122, 122);
  line-height: 30px;
`

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => undefined)

  return (
    <StyledButton type="button" onClick={decoratedOnClick}>
      {children}
    </StyledButton>
  )
}

function Prices({ servicesData }) {
  const servicesCards = servicesData.map((element, key) => {
    const {
      id,
      tytul: title,
      opis: { opis: description },
      obraz: { fluid: image },
    } = element

    return (
      <Card key={id}>
        <Card.Header className="p-0">
          <CustomToggle eventKey={key + 1}>{title}</CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey={key + 1}>
          <StyledCardBody>
            <Row>
              <Col xs={12} lg={8} className="mb-4">
                <ReactMarkdown>{description}</ReactMarkdown>
              </Col>
              <Col xs={12} lg={4}>
                <ImageModal
                  imageFluid={image}
                  title={title}
                  className="image mb-4"
                />
              </Col>
            </Row>
          </StyledCardBody>
        </Accordion.Collapse>
      </Card>
    )
  })

  return (
    <Container className="mt-6 mb-5">
      <Row>
        <h1 className="page-header ml-auto mr-auto">Usługi</h1>
      </Row>
      <Row className="mt-5">
        <Col xs={12}>
          <Accordion defaultActiveKey={1}>{servicesCards}</Accordion>
        </Col>
      </Row>
    </Container>
  )
}

export default Prices
