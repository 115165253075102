import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Services from "../components/Services"

export const query = graphql`
  {
    allContentfulServicesItem(sort: { order: ASC, fields: createdAt }) {
      nodes {
        id
        tytul
        opis {
          opis
        }
        obraz {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`

export default function ServicesPage({ data }) {
  const {
    allContentfulServicesItem: { nodes: servicesData },
  } = data

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>fizjowsulkowski | Usługi</title>
        <meta name="theme-color" content={"white"} />
      </Helmet>
      <Services servicesData={servicesData} />
    </>
  )
}
